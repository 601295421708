






import { Component, Vue } from 'vue-property-decorator'
import UserList from '@/components/user/list.vue'

@Component({ components: { UserList } })
export default class ViewUser extends Vue {

}
