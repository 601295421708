import logCollection, { Log } from '@/models/logs'
import userCollection from '@/models/users'
import UserStore from '@/store/auth'

const logAdd = (type: string, title: string, body: string, notice: boolean | null, link?: string) => {
  const date = new Date()
  const id = date.getTime().toString()
  const user = UserStore.fireUser ? userCollection.doc(UserStore.fireUser.uid) : null
  const log = new Log(id, date, type, title, body, user, notice, link)
  logCollection.doc(id).set(log)
}

export default logAdd
