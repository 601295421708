












































import { Component, Vue, Prop } from 'vue-property-decorator'
import userCollection, { User } from '@/models/users'
import AuthStore from '@/store/auth'
import UserItemContent from '@/components/user/item-content.vue'
import userActions from '@/components/user/userActions'
import logAdd from '@/components/log/add'

@Component({ components: { UserItemContent } })
export default class extends Vue {
  @Prop({ type: Object, required: true }) user!: User
  // actions = ['admin', 'read', 'write']
  // actions = userActions

  expanded = false
  message = {
    title: '', body: '', token: this.user.fcmToken
  }

  changeActions (actions: string[]) {
    const message = `사용자 변경: (${this.user.actions.join(',')} ->${actions.join(',')}))`
    logAdd('user', this.user.displayName, message, false, '/user')
    userCollection.doc(this.user.id).set({ actions }, { merge: true })
  }

  get authActions () {
    if (AuthStore.isDev) return userActions
    return userActions.filter(action => action.value !== 'dev')
  }

  get isMe () {
    if (!AuthStore.fireUser) return false
    return this.user.id === AuthStore.fireUser.uid
  }

  get isAdmin () {
    return AuthStore.isAdmin
  }

  async sendMessage () {
    try {
      const r = await this.$api.post('/user/message', this.message)
      console.log(r.data)
    } finally {
      this.message.title = ''
      this.message.body = ''
    }
  }
}
