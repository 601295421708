import firebase, { db } from '@/plugins/firebase'
import { User } from '@/models/users'

export class Log {
  constructor (
    readonly id: string,
    readonly createdAt: Date,
    readonly type: string,
    readonly title: string,
    readonly body: string,
    readonly user?: firebase.firestore.DocumentReference<User> | null,
    readonly notice?: boolean | null,
    readonly link?: string | null,
    public decodeUser?: User | null
  ) {
  }
}

export const converter: firebase.firestore.FirestoreDataConverter<Log> = {
  toFirestore (model: Partial<Log>) {
    return {
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      type: model.type,
      title: model.title,
      body: model.body,
      user: model.user || null,
      notice: model.notice || false,
      link: model.link || ''
    }
  },
  fromFirestore (snapshot: firebase.firestore.QueryDocumentSnapshot) {
    const data = snapshot.data()

    return new Log(
      snapshot.id,
      (data.createdAt as firebase.firestore.Timestamp)?.toDate(),
      data.type,
      data.title,
      data.body,
      data.user || null,
      data.notice || false,
      data.link || '',
      data.decodeUser || null
    )
  }
}
export default db.collection('logs').withConverter(converter)
