















































import { Component, Vue } from 'vue-property-decorator'
import userCollection, { User } from '@/models/users'
import UserItem from '@/components/user/item.vue'
import HelpSign from '@/components/help/sign.vue'
import firebase from 'firebase'

@Component<UserList>({
  components: { UserItem, HelpSign },
  created () {
    this.subscribe()
  },
  destroyed () {
    if (this.unsubscribe) this.unsubscribe()
  }
})
export default class UserList extends Vue {
  unsubscribe: Function | null = null
  snapshots: firebase.firestore.QueryDocumentSnapshot<User>[] = []
  headers = [
    { value: 'email', text: '이메일' },
    { value: 'displayName', text: '이름' },
    { value: 'createdAt', text: '생성일' },
    { value: 'updatedAt', text: '수정일' }
  ]

  selectedHeader = 'email'

  sortAsc = true

  dialog = false

  private subscribe () {
    this.unsubscribe = userCollection
      .orderBy(this.selectedHeader, this.sort)
      .limit(30)
      .onSnapshot(sn => {
        if (sn.empty) return
        this.snapshots = sn.docs
      })
  }

  changeSort () {
    this.sortAsc = !this.sortAsc
    this.subscribe()
  }

  get items () {
    return this.snapshots.map(sn => {
      return {
        ...sn.data(),
        id: sn.id
      }
    })
  }

  get sort () {
    return this.sortAsc ? 'asc' : 'desc'
  }

  get xs () {
    return this.$vuetify.breakpoint.xs
  }
}
